import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Cal, { getCalApi } from "@calcom/embed-react";
import './App.css';

const LandingPage = () => {
  const [imagesExamplesOne, setExamplesOne] = useState([]);

  useEffect(() => {
    const imageUrlsOne = [
      require('./image1.png'),
      require('./image2.png'),
      require('./image3.png'),
    ];
    setExamplesOne(imageUrlsOne);
  }, []);

  const [imagesExamplesTwo, setExamplesTwo] = useState([]);

  useEffect(() => {
    const imageUrlsTwo = [
      require('./image4.png'),
      require('./image5.png'),
      require('./image6.png'),
    ];
    setExamplesTwo(imageUrlsTwo);
  }, []);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal("ui", {
        styles: { branding: { brandColor: "#000000" } },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
    })();
  }, []);

  return (
    <div className="landing-page">
      <div className="heading-container">
        <h1>We got your attention. You clicked on our AD.</h1>
        <p className='tag'>That's what we do.</p><p className='cta'> Book. Get. Try. Return.</p>
      </div>
      <div className="content-container">
        <div className="image-slider">
          <Carousel className="carousel" showThumbs={false} autoPlay={false}> 
            {imagesExamplesOne.map((imagesExamplesOne, index) => (
              <div key={index}>
                <img src={imagesExamplesOne} alt={`Example ${index + 1}`} />
              </div>
            ))}
          </Carousel>
          <Carousel className="carousel"  showThumbs={false} autoPlay={false}> 
            {imagesExamplesTwo.map((imagesExamplesTwo, index) => (
              <div key={index}>
                <img src={imagesExamplesTwo} alt={`Example ${index + 1}`} />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="form-container">
          <Cal
            calLink="founder-nocircles/introduction"
            style={{ width: "100%", height: "100%", overflow: "scroll" }}
            config={{ layout: 'month_view' }}
          />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
