import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import './LeadCaptureForm.css';

const LeadCaptureForm = () => {
  const form = useRef();
  const [submitted, setSubmitted] = useState(false);
  const vimeoVideoUrl = 'https://player.vimeo.com/video/924136270?autoplay=1&muted=1&loop=1';
  const logoImage = 'whiteLogo.png';
  const clientLogoImage = 'clientLogoImage.png';
  const examples = 'examples.png';

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_mkobh1v', 'template_qkw4m3i', form.current, 'L41LywahkDmk3irkp')
      .then((result) => {
        console.log(result.text);
        setSubmitted(true);
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <div className="lead-capture-container">
      <h1 className="leadTitle">FREE Content Review</h1>
      <p className="subtitle">Limited to 30 spaces.</p>

      <div className="video-container">
        <iframe
          src={vimeoVideoUrl}
          width="960"
          height="540"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="No Circles Media Content Review Video"
        ></iframe>
      </div>

      <div className="logos-section">
        <p>Humans from these companies already have.</p>
        <img src={clientLogoImage} alt="Client logos" className="client-logo-image" />
      </div>
      <p>"These creatives have outperformed anything we'd done before" a human has said.</p>
      {!submitted ? (
        <div className="form-container">
          <div className="form-section">
            <h2>Don't Miss Out:</h2>
            <form ref={form} onSubmit={sendEmail}>
              <input type="text" name="name" placeholder="name*" required />
              <input type="email" name="email" placeholder="email*" required />
              <input type="text" name="company" placeholder="company*" required />
              <input type="text" name="website" placeholder="website*" required />
              <input type="text" name="social_link_one" placeholder="1st social link*" required />
              <input type="text" name="social_link_two" placeholder="2nd social link" />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      ) : (
        <div className="thank-you-message">
          <h2>You made the right choice,<br></br> you'll receive the review when it's done!</h2>
        </div>
      )}

      <div className="bottom-footer">
        <img src={logoImage} alt="No Circles Media Logo" className="white-logo" />
      </div>
      <div className="examples">
        <h2>A lil' Taste</h2>
        <img src={examples} alt="No Circles Media examples" className="examples-img" />
      </div>
      <div className="bottom-footer">
        <img src={logoImage} alt="No Circles Media Logo" className="white-logo" />
      </div>
    </div>
  );
};

export default LeadCaptureForm;
